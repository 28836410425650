import 'jquery';

window.popinManager = function () {
  return {
    bindDemand(demand) {
      jQuery('.ginput_container_text .gform_hidden').each(function () {
        jQuery(this).val(demand);
      });
    }
  }
}

jQuery(document).ready(($) => {
  $('.popin-form-screen').on('scroll', function (e) {
    let scroll = $(this).scrollTop();
    let size = $('.popin-sm-bg').height();

    let popinClose = $('.popin-close');
    let popinBack = $('.popin-back');

    if (scroll > size){
      popinClose.addClass('active');
      popinBack.addClass('active');
    }else {
      popinClose.removeClass('active');
      popinBack.removeClass('active');
    }
  })
});



